import { ButtonLink } from '../../common/components/Button'
import React, { useState } from 'react'
import styles from './AppDownload.module.scss'
import { isMobile } from 'react-device-detect'

type AppDownloadProp = {|
  heading: string,
  link: {|
    text: string,
    to: string,
  |},
  appStore: {|
    image: string,
    ratings: string,
    link: string,
  |},
  googlePlay: {|
    image: string,
    ratings: string,
    link: string,
  |},
|}

type Props = {|
  appDownload: AppDownloadProp,
|}

export function AppDownload({ appDownload }: Props) {
  const { heading, link, appStore, googlePlay } = appDownload
  const [hoveredImage, setHoveredImage] = useState(null)
  const getMouseEvents = (imageType) => {
    if (isMobile) return {}

    return {
      onMouseEnter: () => setHoveredImage(imageType),
      onMouseLeave: () => setHoveredImage(null)
    }
  }

  return (
    <div className={styles.background}>
      <div className={styles.container}>
        <div className={styles.content}>
          <h2 className={styles.heading}>{heading}</h2>
          <ButtonLink
            color="blue"
            text={link.text}
            linkExternal={link.to}
            external={true}
            openInNewTab
          />
        </div>
        <div className={styles.appDownloadContainer}>
          <div className={styles.appDownload}>
            <a
              className={styles.appDownloadLink}
              href={appStore.link}
              {...getMouseEvents('appStore')}
            >
              <img src={appStore.image} className={styles.storeImage} />
              {hoveredImage === 'appStore' && (
                <div className={styles.imagePopup}>
                  <img
                    src={appStore.qrCodeImg}
                    className={styles.popupImage}
                    alt="QR code for App Store"
                  />
                </div>
              )}
            </a>
            <span>⭐️⭐️⭐️⭐️⭐️</span>
            <span className={styles.appDownloadRatings}>
              {appStore.ratings}
            </span>
          </div>
          <div className={styles.appDownload}>
            <a
              className={styles.appDownloadLink}
              href={googlePlay.link}
              {...getMouseEvents('googlePlay')}
            >
              <img src={googlePlay.image} className={styles.storeImage} />
              {hoveredImage === 'googlePlay' && (
                <div className={styles.imagePopup}>
                  <img
                    src={googlePlay.qrCodeImg}
                    className={styles.popupImage}
                    alt="QR code for Google Play"
                  />
                </div>
              )}
            </a>
            <span>⭐️⭐️⭐️⭐️⭐️</span>
            <span className={styles.appDownloadRatings}>
              {googlePlay.ratings}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
